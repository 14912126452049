import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'app/service/dialog.service';
import { DialogConfirmWebRefundTicketComponent } from '../dialog-confirm-web-refund-ticket/dialog-confirm-web-refund-ticket.component';

@Component({
  selector: 'dialog-web-refund-ticket',
  templateUrl: './dialog-web-refund-ticket.component.html',
  styleUrls: ['./dialog-web-refund-ticket.component.scss']
})
export class DialogWebRefundTicketComponent implements OnInit {
  currentLang: string;
  listOrderId: string;
  salesAmount: number;
  refundAmount: number;
  refundFee: number;
  validateMessage: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private translateService: TranslateService, private dialogService: DialogService) {
    this.currentLang = this.translateService.currentLang;
    this.listOrderId = data.listOrderId;
    this.salesAmount = data.salesAmount;
    if (data.refundFee) {
      this.refundFee = data.refundFee;
    }
  }

  ngOnInit(): void {}

  /**
   * validateNumber
   * @param data
   * @returns
   */
  validateNumber(data: any): boolean {
    const pattern = /^[1-9]\d*$/;
    return pattern.test(data);
  }

  /**
   * validateRefundFee
   */
  validateRefundFee() {
    this.validateMessage = '';
    if (!this.refundFee) {
      this.validateMessage = this.translateService.instant('dialog-web-refund-ticket.is-empty');
    } else if (!this.validateNumber(this.refundFee)) {
      this.validateMessage = this.translateService.instant('dialog-web-refund-ticket.is-number');
    }
  }

  /**
   * refund
   */
  confirm() {
    if (this.validateMessage) {
      return;
    }
    const refundAmount = this.refundFee ? this.salesAmount - this.refundFee : this.salesAmount;
    this.dialogService.showDialog(
      DialogConfirmWebRefundTicketComponent,
      {
        data: {
          listOrderId: this.listOrderId,
          salesAmount: this.salesAmount,
          refundFee: this.refundFee,
          refundAmount: refundAmount
        }
      },
      result => {
        // nothing
      }
    );
  }
}
