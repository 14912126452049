import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Common } from 'app/model/entity/common';
import { DialogService } from 'app/service/dialog.service';
import { HelpService } from 'app/service/help.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
@Component({
  selector: 'dialog-help',
  templateUrl: './dialog-help.component.html',
  styleUrls: ['./dialog-help.component.scss']
})
export class DialogHelpComponent implements OnInit {
  /**
   * version CMP
   */
  public versionCMP: string = '2.3.0';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private helpService: HelpService,
    private dialogRef: MatDialogRef<DialogHelpComponent>,
    public dialogService: DialogService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {}

  /**
   * show manual of current soft ware
   */
  public showManualOfCurrentSoftware(): void {
    this.helpService
      .getUrlManualBySoftware(this.translateService.instant('common.software.' + this.data.commonObject.moduleName))
      .subscribe(
        response => {
          if (!response) {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('dialog-error.title'),
                text: this.translateService.instant('dialog-help.error-no-file')
              }
            });
            this.dialogRef.close();
            return;
          }
          window.open(response);
        },
        error => {
          Helper.handleError(error, this.translateService, this.dialogService);
          this.dialogRef.close();
        }
      );
  }
}

export interface DialogData {
  commonObject: Common;
}
